import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { useDispatch } from 'react-redux'
import { useInView } from 'react-intersection-observer'
import { SiteLink } from './SiteLink'
import ProductThumbnail from './ProductThumbnail'
import sliderArrow from '../assets/sliderArrow.svg'
import useRootMargin from '../hooks/window/useRootMargin'
import useHeaderColor from '../hooks/useHeaderColor'
import useFilterProducts from '../hooks/useFilterProducts'
import { setCategoriesFilter } from '../actions'
import { DictionaryContext, LayoutContext } from '../contexts'
import {
  mobileVW,
  desktopVW,
  color,
  zIndex,
  desktopBreakpoint,
  underlineMultiLines
} from '../styles'
import { quart, expo } from 'styles/eases'

const Slider = ({ slider: { title, filters, cta, ctaLink, slides } }) => {
  const dictionary = useContext(DictionaryContext)
  const { productCategories } = useContext(LayoutContext)
  const dispatch = useDispatch()
  const rootMargin = useRootMargin()

  const [filterSelected, setFilterSelected] = useState('all')
  const [refSlider, inViewSlider] = useInView({ rootMargin })

  useHeaderColor(
    inViewSlider,
    {
      left: color.yellow,
      center: color.yellow,
      right: color.black
    },
    {
      left: color.black,
      center: color.yellow,
      right: color.black
    }
  )

  const params = {
    grabCursor: true,
    slidesPerView: 'auto',
    loop: false,
    slidesOffsetBefore: 16,
    slidesOffsetAfter: 16,
    preloadImages: false,
    navigation: {
      nextEl: '.custom-button-next',
      prevEl: '.custom-button-prev'
    },
    breakpoints: {
      1023: {
        slidesOffsetBefore: 60,
        slidesOffsetAfter: 60
      }
    }
  }

  const fileteredProducts = useFilterProducts(slides)

  return (
    <StyledSlider bigTitle={!filters}>
      <Wrapper bigTitle={!filters}>
        <Title bigTitle={!filters}>{title}</Title>
        {filters && (
          <FiltersWrapper>
            <FiltersType>
              <Filter
                aria-label='filter-all'
                selected={filterSelected === 'all'}
                onClick={() => {
                  setFilterSelected('all')
                  dispatch(setCategoriesFilter([]))
                }}
              >
                <span>{dictionary.all}</span>
              </Filter>
              {productCategories.map(({ name }, index) => (
                <Filter
                  key={index}
                  aria-label={`filter-by-${name.toLowerCase()}`}
                  selected={filterSelected === name.toLowerCase()}
                  longText={name.length > 4}
                  onClick={() => {
                    setFilterSelected(name.toLowerCase())
                    dispatch(setCategoriesFilter([name]))
                  }}
                >
                  <span>{name}</span>
                </Filter>
              ))}
            </FiltersType>
          </FiltersWrapper>
        )}
      </Wrapper>

      <SliderWrapper ref={refSlider} length={fileteredProducts.length}>
        {fileteredProducts.length > 0 ? (
          <Swiper {...params} shouldSwiperUpdate>
            {fileteredProducts.map(
              ({ title, slug, productImages, shopifyProduct, preorder }, index) => {
                return (
                  <Slide key={index} slug={slug}>
                    <ProductThumbnail product={{ title, slug, productImages, shopifyProduct, preorder }} />
                  </Slide>
                )
              }
            )}
          </Swiper>
        ) : (
          <NoProductFound>{dictionary.noResults}</NoProductFound>
        )}
      </SliderWrapper>
      {cta && ctaLink && (
        <CtaWrapper>
          <Cta slug={ctaLink.link[0].slug}>{cta}</Cta>
        </CtaWrapper>
      )}
    </StyledSlider>
  )
}

const StyledSlider = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${mobileVW(45)};

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${({ bigTitle }) =>
      bigTitle ? desktopVW(70) : desktopVW(90)};
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: ${({ bigTitle }) =>
    bigTitle
      ? `${mobileVW(16)} ${mobileVW(16)} ${mobileVW(68)}`
      : `${mobileVW(16)} ${mobileVW(16)} ${mobileVW(32)}`};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${({ bigTitle }) =>
      bigTitle
        ? `0 ${desktopVW(60)} ${desktopVW(70)}`
        : `0 ${desktopVW(60)} ${desktopVW(40)}`};
  }
`

const Title = styled.h2`
  position: relative;
  font-size: ${({ bigTitle }) => (bigTitle ? mobileVW(42) : mobileVW(40))};
  line-height: ${({ bigTitle }) => (bigTitle ? mobileVW(35) : mobileVW(34))};
  padding: 0 ${({ bigTitle }) => (bigTitle ? mobileVW(35) : mobileVW(16))};
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${({ bigTitle }) => (bigTitle ? desktopVW(80) : desktopVW(40))};
    line-height: ${({ bigTitle }) =>
      bigTitle ? desktopVW(68) : desktopVW(34)};
    padding: 0 ${desktopVW(425)};
  }
`

const FiltersWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-left: -${mobileVW(16)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-left: -${desktopVW(16)};
  }
`

const FiltersType = styled.div`
  display: flex;
  justify-content: center;
  padding: 0;
`

const Filter = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  font-weight: bold;
  height: ${mobileVW(44)};
  width: ${mobileVW(44)};
  background: ${({ selected }) => (selected ? color.yellow : 'transparent')};
  margin: ${({ longText }) =>
    longText
      ? `${mobileVW(10)} ${mobileVW(30)}`
      : `${mobileVW(10)} ${mobileVW(15)}`};
  border-radius: 100%;
  white-space: nowrap;

  span {
    position: absolute;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(16)};
    line-height: ${desktopVW(20)};
    height: ${desktopVW(44)};
    width: ${desktopVW(44)};
    margin: ${({ longText }) =>
      longText
        ? `${desktopVW(10)} ${desktopVW(40)}`
        : `${desktopVW(10)} ${desktopVW(20)}`};
  }
`

const SliderWrapper = styled.div`
  position: relative;
  padding: 0;

  a:last-child {
    margin-right: 0;
  }

  .custom-button-next, .custom-button-prev {
    height: ${mobileVW(101)};
    width: ${mobileVW(101)};
    top: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${sliderArrow});
    position: absolute;
    z-index: ${zIndex.low};
    cursor: pointer;
    opacity: ${({ length }) => length < 1 ? '0' : '1'};

    &:focus {
      outline: none;
    }
  }
  .custom-button-next {
    right: 0;
    transform: translateY(-50%);
  }

  .custom-button-prev {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: ${desktopBreakpoint}) {
    .custom-button-next, .custom-button-prev {
      opacity: ${({ length }) => length < 4 ? '0' : '1'};
    }

    .custom-button-next {
      height: ${desktopVW(151)};
      width: ${desktopVW(151)};
      right: ${desktopVW(54)};
    }

    .custom-button-prev {
      height: ${desktopVW(151)};
      width: ${desktopVW(151)};
      left: ${desktopVW(54)};
    }
  }
`

const Slide = styled(SiteLink)`
  background: ${color.grey};
  margin-right: ${mobileVW(16)};
  overflow: hidden;

  &.swiper-slide {
    height: ${mobileVW(412)};
    width: ${mobileVW(297)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-right: ${desktopVW(60)};

    &.swiper-slide {
      height: ${desktopVW(491)}!important;
      width: ${desktopVW(360)}!important;
    }

    p {
      transition: color 0.8s ${quart.inOut};
    }

    &:hover {
      p {
        transition-duration: 1s;
        transition-timing-function: ${expo.out};
        color: ${color.yellow};
      }
    }
  }
`

const NoProductFound = styled.div`
  position: relative;
  top: ${mobileVW(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: ${mobileVW(120)};
  font-size: ${mobileVW(13)};
  line-height: ${mobileVW(24)};

  @media (min-width: ${desktopBreakpoint}) {
    top: 0;
    height: ${desktopVW(320)};
    font-size: ${desktopVW(13)};
    line-height: ${desktopVW(20)};
  }
`

const CtaWrapper = styled.div`
  text-align: center;
  max-width: ${mobileVW(220)};
  padding: ${mobileVW(45)} ${desktopVW(30)} ${mobileVW(55)};
  margin: 0 auto;

  @media (min-width: ${desktopBreakpoint}) {
    max-width: ${desktopVW(220)};
    padding: ${desktopVW(75)} ${desktopVW(30)} ${desktopVW(25)};
  }
`

const Cta = styled(SiteLink)`
  font-size: ${mobileVW(30)};
  line-height: ${mobileVW(36)};
  font-weight: normal;

  ${underlineMultiLines}

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(30)};
    line-height: ${desktopVW(36)};
  }
`

export default Slider
